import SimpleTab from "../../js/tabs";

export default () => {
  window.addEventListener("load", () => {
    const buttonsContainer = document.querySelector(
      ".program-old__btn-container"
    );
    const tabs = document.querySelector(".program-old__tabs");
    const multiplySpeakers = document.querySelectorAll(
      ".program-old__event-avatar.animate"
    );
    if (!tabs) {
      return;
    }

    if (multiplySpeakers.length) {
      multiplySpeakers.forEach(item => {
        const images = item.querySelectorAll("img");

        setInterval(() => {
          const showElem = item.querySelector(".show");
          if (!showElem) {
            images[0].classList.add("show");
          } else {
            showElem.classList.remove("show");
            if (showElem.nextSibling) {
              showElem.nextSibling.classList.add("show");
            } else {
              images[0].classList.add("show");
            }
          }
        }, 1000);
      });
    }

    const tabList = new SimpleTab(".program-old", buttonsContainer, tabs);
  });
};
