import SimpleBar from "simplebar/dist/simplebar.min";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import utils from "./utils";
import MainFs from "../blocks/MainFs/MainFs";
import MainCountUp from "../blocks/MainCountUp/MainCountUp";
import News from "../blocks/News/News";
import NextPage from "../blocks/NextPage/NextPage";
import header from "../blocks/snippets/header/header";
import Program2019 from "../blocks/Program2019/Program2019";
import Reviews from "../blocks/Reviews/Reviews";
import Participant from "../blocks/Participant/Participant";
import PhotoReport from "../blocks/PhotoReport/PhotoReport";
import Program2020 from "../blocks/Program2020/Program2020";
import ProgramModal from "../blocks/ProgramModal/ProgramModal";

export { ScrollToPlugin };

// TODO: remove this!!!
// setTimeout(() => {
  const simpleBar = new SimpleBar(
    document.querySelector("[data-custom-scrollbar]")
  );

  window.simplebar = simpleBar;
// }, 2000);

utils();
MainFs();
MainCountUp();
News();
NextPage();
header();
Program2019();
Reviews();
Participant();
PhotoReport();
Program2020();
ProgramModal();
