import Flickity from "flickity";
import lightGallery from "lightgallery.js";
import lightGalleryThunmnail from "lg-thumbnail.js";
import lightGalleryFullScreen from "lg-fullscreen.js";
import lightGalleryAutoplay from "lg-autoplay.js";
import "lg-video.js";

export default () => {
  const news = document.querySelector(".news__slider");
  if (news) {
    const newsSlider = new Flickity(news, {
      // adaptiveHeight: true,
      lazyLoad: 4,
      cellAlign: "left",
      percentPosition: false,
      contain: true,
      groupCells: true,
      pageDots: false,
      prevNextButtons: false
    });
    document.querySelector(".news__nav-left").addEventListener("click", () => {
      newsSlider.previous(true);
    });
    document.querySelector(".news__nav-right").addEventListener("click", () => {
      newsSlider.next(true);
    });

    // gallery example
    if (document.querySelector("#gallery")) {
      // window.lightGallery(document.getElementById("gallery"), {
      //   thumbnail: true,
      //   mode: "lg-fade"
      // });
      // window.lightGallery(document.getElementById("news__slider"));
    }
    Array.from(document.querySelectorAll(".news__slider-item-wrap")).forEach(
      item => {
        window.lightGallery(item);
      }
    );
  }
};
