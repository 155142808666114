export default () => {
  // VH for mobile browsers. css --> height: calc(var(--vh, 1vh) * 100);
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // Recalculate on resize event
  window.addEventListener("resize", () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  function classesAddWithDelay(element, classInstant, classDelay, delay) {
    element.classList.add(classInstant);
    setTimeout(() => {
      element.classList.add(classDelay);
    }, delay);
  }

  function classesRemoveWithDelay(element, classInstant, classDelay, delay) {
    element.classList.remove(classInstant);
    setTimeout(() => {
      element.classList.remove(classDelay);
    }, delay);
  }

  function lazyImages() {
    const images = document.querySelectorAll(".lz-img");
    // eslint-disable-next-line no-use-before-define
    const observer = new IntersectionObserver(onIntersection, {
      rootMargin: "800px"
    });

    function onIntersection(imageEntities) {
      imageEntities.forEach(image => {
        if (image.isIntersecting) {
          if (image.target.getAttribute("data-srcset")) {
            // eslint-disable-next-line no-param-reassign
            image.target.srcset = image.target.dataset.srcset;
          }
          if (image.target.getAttribute("data-src")) {
            // eslint-disable-next-line no-param-reassign
            image.target.src = image.target.dataset.src;
          }
          observer.unobserve(image.target);
        }
      });
    }

    images.forEach(image => observer.observe(image));
  }

  function lightgallerySingle() {
    const lightGalleryButtons = Array.from(
      document.querySelectorAll("[data-lightgallery]")
    );
    lightGalleryButtons.forEach(item => {
      window.lightGallery(item, {
        selector: "this",
        thumbnail: false
        // loadVimeoThumbnail: false,
      });
    });
  }

  lightgallerySingle();
  lazyImages();
  window.customUtils = {
    classesAddWithDelay,
    classesRemoveWithDelay
  };
};

export function throttle(func, ms) {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function() {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}
