import Flickity from "flickity";

export default () => {
  const reportSlider = document.querySelector(".photo-report__wrap");
  if (reportSlider && window.innerWidth < 768) {
    new Flickity(reportSlider, {
      cellSelector: ".photo-report__item_photo",
      imagesLoaded: true,
      percentPosition: false,
      cellAlign: "left",
      prevNextButtons: false,
      pageDots: false
    });
  }
};
