import Flickity from "flickity";

function scrollAnchors(e, respond = null) {
  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
  e.preventDefault();
  const targetID = respond
    ? respond.getAttribute("href")
    : this.getAttribute("href");
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);
  window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
  const checkIfDone = setInterval(function() {
    const atBottom =
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (distanceToTop(targetAnchor) === 0 || atBottom) {
      targetAnchor.tabIndex = "-1";
      targetAnchor.focus();
      window.history.pushState("", "", targetID);
      clearInterval(checkIfDone);
    }
  }, 100);
}

export default () => {
  const participantSliderNode = document.querySelector(".participant__slider");
  window.addEventListener("load", () => {
    if (participantSliderNode) {
      const participantSlider = new Flickity(participantSliderNode, {
        wrapAround: true,
        cellAlign: window.innerWidth > 767 ? "center" : "left",
        cellSelector: ".participant__slider-item",
        prevNextButtons: false,
        pageDots: false,
        groupCells: window.innerWidth > 767 ? 2 : 1,
        on: {
          change(index) {
            const banners = Array.from(
              document.querySelectorAll(".participant__slider-banner")
            );
            if (index) {
              banners.forEach(item => {
                item.classList.add("hide");
              });
            } else {
              banners.forEach(item => {
                item.classList.remove("hide");
              });
            }
          }
        }
      });

      document
        .querySelector(".participant__slider-prev")
        .addEventListener("click", () => {
          participantSlider.previous(true);
        });
      document
        .querySelector(".participant__slider-next")
        .addEventListener("click", () => {
          participantSlider.next(true);
        });
    }

    const anchors = Array.from(
      document.querySelectorAll(".participant__anchor-item")
    );

    anchors.forEach(anchor => {
      anchor.addEventListener("click", e => {
        const sectionId = e.currentTarget.dataset.section;
        if (sectionId) {
          console.log(
            TweenLite.to(window.simplebar.contentWrapperEl, 1, {
              scrollTo: { y: sectionId, offsetY: 120 }
            })
          );
        }
      });
    });
  });
};
