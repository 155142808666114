import Flickity from "flickity";
import SimpleTab from "../../js/tabs";

export default () => {
  window.addEventListener("load", () => {
    const buttonsContainer = document.querySelector(".reviews__btn-container");
    const tabs = document.querySelector(".reviews__tabs");
    if (!tabs) {
      return;
    }

    const tabList = new SimpleTab(".reviews", buttonsContainer, tabs);

    const reviewsTab = Array.from(
      document.querySelectorAll(".reviews__tabs-item")
    );
    if (reviewsTab.length && window.innerWidth < 1024) {
      reviewsTab.forEach(item => {
        new Flickity(item, {
          cellSelector: ".reviews__item",
          cellAlign: "left",
          prevNextButtons: false
        });
      });
    }
  });
};
