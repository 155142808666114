import TweenMax from "gsap/TweenMax";
import TweenLite from "gsap/TweenLite";
import Scrollmagic from "scrollmagic";
import addIndicators from "scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min";
import gsapPlugin from "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min";
// import {TimelineMax} from "gsap/TimelineMax";

function setSrcImages(imageEntities) {
  imageEntities.forEach(image => {
    if (image) {
      // eslint-disable-next-line no-param-reassign
      image.src = image.dataset.src;
    }
  });
}

class ChangeSpeaker {
  constructor() {
    this.btnChanger = document.querySelector(".main-fs__photo-changer");
    this.activeSpeaker = 0;
    this.timer = null;
    this.wolfHidden = false;
    this.speakers = Array.from(
      document.querySelectorAll(".main-fs__wolf .speaker")
    );
    this.speakersArrow = Array.from(
      document.querySelectorAll(".main-fs__bottom-wolf-arrow .speaker")
    );
    this.speakersCount = this.speakers.length;

    this.btnChanger.addEventListener("mouseover", () => {
      this.timer = setInterval(() => {
        if (!this.wolfHidden) {
          document
            .querySelector(".main-fs__wolf .wolf")
            .classList.add("hidden");
          document
            .querySelector(".main-fs__bottom-wolf-arrow .wolf")
            .classList.add("hidden");
          this.wolfHidden = true;
        }
        this.speakers[this.activeSpeaker].classList.remove("show");
        this.speakersArrow[this.activeSpeaker].classList.remove("show");
        if (this.speakersCount > this.activeSpeaker + 1) {
          this.activeSpeaker = this.activeSpeaker + 1;
        } else {
          this.activeSpeaker = 0;
        }
        this.speakers[this.activeSpeaker].classList.add("show");
        this.speakersArrow[this.activeSpeaker].classList.add("show");
      }, 200);
    });

    this.btnChanger.addEventListener("mouseout", () => {
      clearInterval(this.timer);
    });
  }
}

export default () => {
  const controller = new Scrollmagic.Controller();
  const mainFs = document.querySelector(".main-fs");

  if (mainFs) {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      setSrcImages(
        document.querySelectorAll(
          ".main-fs__wolf .speaker:nth-child(-n+4), .main-fs__bottom-wolf-arrow .speaker"
        )
      );
    }

    window.addEventListener("load", () => {
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      ) {
        setSrcImages(
          document.querySelectorAll(
            ".main-fs__wolf .speaker:nth-child(n+4)"
          )
        );
      }
    })

    window.addEventListener("load", () => {
      const mainFsTl = new TimelineMax({ repeat: 0, paused: true });
      const mainFsContent = document.querySelectorAll(
        ".main-fs__wrap, .main-fs__photo-changer, .main-fs__mouse, .main-fs__wolf .micro"
      );

      mainFsTl
        .add(TweenMax.set(mainFsContent, { opacity: 0 }), 0)
        // .add(TweenMax.set(['.main-fs__fest', '.main-fs__ever'], {opacity: 0}), 0)
        // .add(TweenMax.fromTo('.main-fs__fest', 0.3, {opacity: 0}, {opacity: 1}), "+=1")
        .add(
          TweenMax.fromTo(
            ".main-fs__wolf",
            1.2,
            { opacity: 0, scale: 0.95 },
            { opacity: 1, scale: 1 }
          ),
          "+=1"
        )
        // .add(TweenMax.fromTo('.main-fs__ever', 0.3, {opacity: 0}, {opacity: 1}), "-=0.1")
        .add(TweenMax.to(mainFsContent, 1, { opacity: 1 }), "-=0.4");

      mainFsTl.play();
      // setTimeout(() => {
      // }, 900);
    });

    // new Scrollmagic.Scene({
    //   triggerElement: ".main-fs",
    //   triggerHook: 0,
    //   duration: "100%"
    // })
    //   .setTween(TweenMax.to(".main-fs", 1, { opacity: 0.75 }))
      // .setPin(".main-fs", { pushFollowers: false })
      // .addIndicators()
      // .addTo(controller);

    window.lightGallery(document.querySelector(".main-fs__showreel"), {
      selector: "this",
      thumbnail: false
      // loadVimeoThumbnail: false,
    });

    // parallax effect everfest
    if (window.innerWidth > 900) {
      const rect = document.querySelector(".main-fs").getBoundingClientRect();
      const mouse = { x: 0, y: 0, moved: false };

      mainFs.onmousemove = e => {
        mouse.moved = true;
        mouse.x = e.clientX - rect.left;
        mouse.y = e.clientY - rect.top;
      };

      function parallaxIt(target, movement) {
        TweenMax.to(target, 0.3, {
          x: ((mouse.x - rect.width / 2) / rect.width) * movement,
          y: ((mouse.y - 60 - rect.height / 2) / rect.height) * movement
        });
      }

      // Ticker event will be called on every frame
      TweenLite.ticker.addEventListener("tick", function() {
        if (mouse.moved) {
          parallaxIt(".main-fs__ever", -40);
          parallaxIt(".main-fs__fest", -20);
          parallaxIt(".main-fs__wolf .micro", -10);
        }
        mouse.moved = false;
      });
    }

    new ChangeSpeaker();
  }
};
