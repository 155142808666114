export default class SimpleTab {
  constructor(container, buttonsContainer, tabs) {
    this.buttonsContainer = buttonsContainer;
    this.tabs = tabs;
    this.container = container;

    this.buttonsContainer.addEventListener("click", event => {
      const oldActiveBtn = document.querySelector(
        `${this.container} .active-btn`
      );
      const newActiveBtn = event.target.closest("[data-tab]");
      if (newActiveBtn) {
        oldActiveBtn.classList.remove("active-btn");
        newActiveBtn.classList.add("active-btn");
        const index = newActiveBtn.dataset.tab;
        this.openTab(index);
      }
    });
  }

  openTab(index) {
    const oldActiveTab = this.tabs.querySelector(
      `${this.container} .active-tab`
    );
    if (oldActiveTab) {
      oldActiveTab.classList.remove("active-tab");
    }
    this.tabs.children[index - 1].classList.add("active-tab");
  }
}
