import SimpleTab from "../../js/tabs";

function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
  options = Object.assign({ path: "/" }, options);

  if (options.expires.toUTCString) {
    options.expires = options.expires.toUTCString();
  }

  var updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (var optionKey in options) {
    updatedCookie += "; " + optionKey;
    var optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export default () => {
  window.addEventListener("load", () => {
    const buttonsContainer = document.querySelector(".program__btn-container");
    const tabs = document.querySelector(".program__tabs");
    const multiplySpeakers = document.querySelectorAll(
      ".program-old__event-avatar.animate"
    );

    if (!tabs) {
      return;
    }

    if (multiplySpeakers.length) {
      multiplySpeakers.forEach(item => {
        const images = item.querySelectorAll("img");

        setInterval(() => {
          const showElem = item.querySelector(".show");
          if (!showElem) {
            images[0].classList.add("show");
          } else {
            showElem.classList.remove("show");
            if (showElem.nextSibling) {
              showElem.nextSibling.classList.add("show");
            } else {
              images[0].classList.add("show");
            }
          }
        }, 1000);
      });
    }

    const tabList = new SimpleTab(".program", buttonsContainer, tabs);
  });

  document.querySelectorAll("[data-program-popup]").forEach(item => {
    if (getCookie("isShownProgramPopup") !== "true") {
      item.classList.add("show");
      setCookie("isShownProgramPopup", "true", {
        expires: new Date(Date.now() + 86400e3)
      });
    }
  });

  document.querySelectorAll("[data-close-program-popup]").forEach(item => {
    item.addEventListener("click", e => {
      e.currentTarget.closest(".program__popup").classList.remove("show");
    });
  });
};
