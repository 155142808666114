import TweenMax from "gsap/TweenMax";
import Scrollmagic from "scrollmagic";
import addIndicators from "scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min";
import gsapAnimation from "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min";

export default () => {
  const controller = new Scrollmagic.Controller();

  new Scrollmagic.Scene({
    triggerElement: ".next-page",
    triggerHook: 1
  })
    .setTween(TweenMax.from(".next-page__arrow", 0.3, { width: "10%" }))
    // .addIndicators()
    .addTo(controller);
};
