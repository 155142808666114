import MicroModal from "micromodal";
import Flickity from "flickity";

export default () => {
  window.MicroModal = MicroModal;

  const onProgramShow = modal => {
    modal.querySelectorAll(".program-modal__slider").forEach(item => {
      window.modalProgramSlider = new Flickity(item, {
        cellAlign: "left",
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        cellSelector: ".program-modal__slider-item",
        on: {
          dragStart: () => {
            item.classList.add("off-pointer-events");
          },
          settle: () => {
            item.classList.remove("off-pointer-events");
          }
        }
      });

      window.lightGallery(item, {
        selector: ".program-modal__slider-item",
        thumbnail: false
        // loadVimeoThumbnail: false,
      });

      item
        .querySelector(".program-modal__slider-btn._prev")
        .addEventListener("click", () => {
          window.modalProgramSlider.previous();
        });
      item
        .querySelector(".program-modal__slider-btn._next")
        .addEventListener("click", () => {
          window.modalProgramSlider.next();
        });
    });
  };

  const onProgramClose = modal => {
    // window.modalProgramGallery.destroy(true);
    window.modalProgramSlider.destroy();
    window.lgData[
      modal.querySelector(".program-modal__slider").getAttribute("lg-uid")
    ].destroy(true);

    // eslint-disable-next-line no-param-reassign
    modal.querySelector(".program-modal__speakers").innerHTML = "";

    const modalSlider = modal.querySelector(".program-modal__slider");
    modalSlider.style.display = "block";
    modalSlider
      .querySelector(".program-modal__slider-btn._prev")
      .removeEventListener("click", () => {
        window.modalProgramSlider.previous();
      });
    modalSlider
      .querySelector(".program-modal__slider-btn._next")
      .removeEventListener("click", () => {
        window.modalProgramSlider.next();
      });
    modal.querySelectorAll("a.program-modal__slider-item").forEach(item => {
      modalSlider.removeChild(item);
    });
  };

  MicroModal.init({
    onShow: onProgramShow,
    onClose: onProgramClose,
    // openTrigger: "data-program-open",
    // closeTrigger: "data-program-close",
    disableScroll: true,
    disableFocus: false,
    // awaitOpenAnimation: true,
    awaitCloseAnimation: true
    // debugMode: false
  });

  // open program modal
  document.querySelectorAll("[data-program-trigger]").forEach(item => {
    item.addEventListener("click", () => {
      const event = item.closest(".program-old__tabs-line");
      // get all data about event
      const eventTime = event.querySelector(".program-old__event-time")
        .textContent;
      const eventTitle = event.querySelector(".program-old__event-title")
        .textContent;
      const speakerNames = [];
      const speakerFbLinks = [];
      const detailContent = event.querySelector(".program-old__detail")
        .innerHTML;
      event
        .querySelectorAll(".program-old__event-fb span.name")
        .forEach(speaker => {
          speakerNames.push(speaker.textContent);
        });

      event.querySelectorAll(".program-old__event-fb").forEach(speaker => {
        speakerFbLinks.push(speaker.href);
      });

      const modalImages = JSON.parse(event.dataset.modalImages);

      // set data to modal
      const programModal = document.getElementById("modal-1");
      programModal.querySelector(
        ".program-modal__time"
      ).textContent = eventTime;
      programModal.querySelector(
        ".program-modal__title"
      ).textContent = eventTitle;

      const speakerTemplate = programModal.querySelector("#template-speaker");

      speakerNames.forEach((speaker, index) => {
        speakerTemplate.content.querySelector("a").href = speakerFbLinks[index];
        speakerTemplate.content.querySelector("span").textContent = speaker;

        programModal
          .querySelector(".program-modal__speakers")
          .appendChild(document.importNode(speakerTemplate.content, true));
      });

      programModal.querySelector(
        ".program-modal__description-wrap"
      ).innerHTML = detailContent;

      const imageTemplate = programModal.querySelector(
        "#template-slider-image"
      );
      modalImages.forEach(image => {
        imageTemplate.content.querySelector(
          ".program-modal__slider-item"
        ).href = image.link;
        imageTemplate.content.querySelector("img").src = image.poster;

        if (image.isVideo) {
          imageTemplate.content
            .querySelector(".program-modal__slider-item")
            .classList.add("_video");
        } else {
          imageTemplate.content
            .querySelector(".program-modal__slider-item")
            .classList.remove("_video");
        }

        programModal
          .querySelector(".program-modal__slider")
          .appendChild(document.importNode(imageTemplate.content, true));
      });

      if (!modalImages.length) {
        programModal.querySelector(".program-modal__slider").style.display =
          "none";
      }

      MicroModal.show("modal-1", {
        onShow: onProgramShow,
        onClose: onProgramClose,
        // openTrigger: "data-program-open",
        // closeTrigger: "data-program-close",
        disableScroll: true,
        disableFocus: false,
        // awaitOpenAnimation: true,
        awaitCloseAnimation: true
        // debugMode: false
      });
    });
  });

  // close program modal
  document.querySelectorAll("[data-program-close]").forEach(item => {
    item.addEventListener("click", e => {
      if (e.target === e.currentTarget) {
        MicroModal.close("modal-1");
      }
    });
  });
};
