import TweenMax from "gsap/TweenMax";
import TimelineMax from "gsap/TimelineMax";
import Scrollmagic from "scrollmagic";
import addIndicators from "scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min";
import gsapAnimation from "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min";
import { throttle } from "../../../js/utils";

export default () => {
  const burgerBtn = document.querySelector(".burger-btn");
  const header = document.querySelector(".header");
  const controller = new Scrollmagic.Controller();
  // let isMenuOpen = false;

  const menuTl = new TimelineMax({ repeat: 0, paused: true })
    .add(TweenMax.set(".menu__bg img", { opacity: 0 }), 0)
    .add(TweenMax.set(".menu__main-link", { opacity: 0 }), 0)
    .add(TweenMax.set(".menu__info", { opacity: 0 }), 0)
    .add(TweenMax.set(".menu__soc", { opacity: 0 }), 0)
    .add(TweenMax.fromTo(".menu__bg", 0.3, { opacity: 0 }, { opacity: 1 }), 0)
    .add(
      TweenMax.fromTo(
        ".menu__bg .menu__bg-main",
        0.2,
        { opacity: 0 },
        { opacity: 1 }
      ),
      "+=0"
    )
    .add(
      TweenMax.fromTo(
        ".menu__bg .menu__bg-top",
        0.1,
        { opacity: 0 },
        { opacity: 1 }
      ),
      "+=0"
    )
    .add(
      TweenMax.fromTo(
        ".menu__bg .menu__bg-bottom",
        0.1,
        { opacity: 0 },
        { opacity: 1 }
      ),
      "+=0"
    )
    .add(
      TweenMax.staggerFromTo(
        ".menu__main-link",
        0.3,
        { opacity: 0, x: -20 },
        { opacity: 1, x: 0 },
        0.2
      ),
      "+=0"
    )
    .add(
      TweenMax.fromTo(
        ".menu__soc, .menu__info",
        0.3,
        { opacity: 0 },
        { opacity: 1 }
      ),
      "-=0.2"
    );

  if (burgerBtn) {
    burgerBtn.addEventListener("click", () => {
      burgerBtn.classList.toggle("open");
      document.querySelector(".menu").classList.toggle("open");
      menuTl.restart();
    });
  }

  // TODO: remove this set timeout!!!
  // setTimeout(() => {
    window.simplebar.contentWrapperEl.addEventListener(
      "scroll",
      throttle(() => {
        if (window.simplebar.contentWrapperEl.scrollTop > 0) {
          header.classList.add("header_bg");
          burgerBtn.classList.add("burger-btn_fixed");
        } else {
          header.classList.remove("header_bg");
          burgerBtn.classList.remove("burger-btn_fixed");
        }
      }, 150)
    );
  // }, 3000);

  if (document.querySelector(".main-fs")) {
    header.classList.add("header_hide");

    new Scrollmagic.Scene({
      triggerElement: ".main-page__content",
      triggerHook: 0
    })
      .on("enter", () => {
        header.classList.remove("header_hide");
      })
      .on("leave", () => {
        header.classList.add("header_hide");
      })
      .addTo(controller);
  }
};
