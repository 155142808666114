import { CountUp } from "countup.js/dist/countUp.min";
import Scrollmagic from "scrollmagic";

export default () => {
  const countList = document.querySelector(".count-up__list");
  if (countList) {
    const controller = new Scrollmagic.Controller();
    const countsData = JSON.parse(countList.dataset.json);
    const countNodes = countList.querySelectorAll(
      ".count-up__item-number:not(.count-up__item-number_shadow)"
    );

    const countUpJsItems = [];
    Array.from(countNodes).forEach((itemNode, index) => {
      const options = {
        duration: 2,
        suffix: countsData[index].suffix,
        separator: ""
      };
      countUpJsItems.push(
        new CountUp(itemNode, countsData[index].number, options)
      );
    });

    new Scrollmagic.Scene({
      triggerElement: ".count-up",
      triggerHook: 0.9
    })
      .on("enter", () => {
        countUpJsItems.forEach((item, index) => {
          setTimeout(() => {
            item.start();
          }, index * 200);
        });
      })
      // .addIndicators()
      .addTo(controller);
  }
};
